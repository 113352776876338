/**
 * NOTE: This button does not accept an onClick/onSubmit prop. Form submissions
 * should be captured and handled in the `<form onSubmit="" />` property. Expect
 * default behavior from this otherwise.
 */
import cx from "classnames";
import { useField } from "formik";

import Loading from "components/atoms/loading";
import IconButton from "components/atoms/buttons/icon";

const Submit = ({
  label,
  disabled = false,
  loading = false,
  className,
  ...props
}) => {
  const [field, meta] = useField(props);
  return (
    <button
      type="submit"
      className={cx("mt-8", {
        "opacity-60": disabled,
      })}
      disabled={loading || disabled}
    >
      <IconButton text={loading ? "•••" : label} />
    </button>
  );
};

export default Submit;
