/**
 * TODO: Get this to a place where props can be passed directly on the `<Icon />`
 */
import React from "react";

const withIcon = (Icon, intrinsicProps = { outline: false }) => {
  return class IconHOC extends React.PureComponent {
    render() {
      const { outline } = intrinsicProps;
      const props = {
        viewBox: outline ? "0 0 24 24" : "0 0 20 20",
        stroke: outline ? "currentColor" : null,
        fill: outline ? "transparent" : "currentColor"
      }
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon inline-block"
          {...props}
        >
          <Icon />
          <style jsx>{`
            .icon {
              width: 1rem;
              height: 1rem;
            }
          `}</style>
        </svg>
      )
    }
  }
}

export default withIcon;
