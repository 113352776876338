import withIcon from "../withIcon";

const InfoIcon = () => (
  <path
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
  />
);

export default withIcon(InfoIcon, { outline: true });
