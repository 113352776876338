import { useField } from "formik";

import cx from "classnames";

const Textarea = ({
  label,
  help,
  containerClass = "block mt-8",
  labelClass = "mb-2 text-sm text-grey-dark font-semibold",
  inputClass = "block w-full outline-none shadow-solid border rounded-md rounded-br-none overflow-hidden p-3",
  ...props
}) => {
  const [field, meta] = useField(props);
  return (
    <div className={containerClass}>
      {label && (
        <label className={labelClass} htmlFor={props.id || props.name}>
          {label}
        </label>
      )}

      <textarea
        {...field}
        {...props}
        style={{ resize: "none" }}
        className={cx(inputClass, {
          "border-fire": meta.error,
          "border-grey-dark": !meta.error && meta.touched,
          "border-grey": !meta.error && !meta.touched,
        })}
      />

      {meta.touched && meta.error ? (
        <p className="mt-1 ml-3 text-sm text-fire font-semibold">
          {meta.error}
        </p>
      ) : null}

      {help ? <p className="mt-1 text-sm text-grey-dark">{help}</p> : null}
    </div>
  );
};

export default Textarea;
