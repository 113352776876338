import cx from "classnames";
import { useField } from "formik";

const Toggle = ({ label, className, ...props }) => {
  const [field, meta] = useField({ ...props, type: "checkbox" });

  return (
    <div className={className}>
      <p className="text-sm text-grey-dark font-semibold mb-1">{label}</p>
      <div className="relative overflow-hidden rounded inline-block w-16 shadow">
        <input
          type="checkbox"
          className="hidden" // TODO: visual hide only
          {...field}
          {...props}
        />
        <label
          htmlFor={props.id || props.name}
          className={cx(
            "relative block overflow-hidden rounded h-6",
            field.checked ? "bg-earth" : "bg-grey-light",
            props.disabled ? "cursor-normal" : "cursor-pointer"
          )}
        >
          <span
            className={cx(
              "absolute left-0 w-8 h-full shadow-inset bg-white transition transform",
              { "translate-x-full": field.checked }
            )}
          />
        </label>
      </div>
    </div>
  );
};

export default Toggle;
