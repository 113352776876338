import React from "react";
import cx from "classnames";
import AlertIcon from "components/icon/solid/alert";
import InfoIcon from "components/icon/solid/info";

const AlertStatus = ({ status }) => {
  if (!status) {
    return null;
  }

  const Icon = status.type === "info" ? InfoIcon : AlertIcon;

  return (
    <div
      className={cx(
        "flex items-center my-6 rounded-md rounded-br-none border p-3 text-sm",
        {
          "border-fire text-fire": status.type === "error",
          "border-blue text-blue": status.type === "info",
        }
      )}
    >
      <Icon />

      <p className="ml-2">{status.message}</p>
    </div>
  );
};

export default AlertStatus;
