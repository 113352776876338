import React, { useEffect, useState } from "react";
import { useField } from "formik";

import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Check from "components/icon/solid/check";
import Alert from "components/icon/solid/alert";

const Input = ({
  label,
  icon,
  helpText,
  toggleEdit = false,
  onToggle = () => {},
  containerClass = "block mt-8",
  labelClass = "mb-2 text-sm text-grey-dark font-semibold",
  inputClass = "w-full border border-grey focus:border-grey-dark rounded rounded-r-none p-2",
  inputContainerClass = "w-64 relative",
  helpClass = "mb-2 text-xs text-grey-dark",
  shadowed = false,
  ...props
}) => {
  const [field, meta] = useField(props);
  const [changeField, setChangeField] = useState(!toggleEdit);

  return (
    <div className={containerClass}>
      {label && (
        <label className={labelClass} htmlFor={props.name}>
          {icon && (
            <>
              <FontAwesomeIcon
                className="text-grey hover:text-grey-dark cursor-pointer"
                icon={icon}
              />{" "}
            </>
          )}
          {label}
        </label>
      )}
      {helpText && <p className={helpClass}>{helpText}</p>}

      <div className="flex">
        <div
          className={cx(
            {
              "shadow-solid border rounded-md rounded-br-none overflow-hidden": shadowed,
              "border-fire": meta.error,
              "border-grey-dark": !meta.error && meta.touched,
              "border-grey": !meta.error && !meta.touched,
            },
            inputContainerClass
          )}
        >
          <input
            {...field}
            {...props}
            disabled={props.disabled || !changeField}
            className={inputClass}
          />

          <span
            className={cx(
              "absolute right-0 top-0 my-1 mr-2 transform translate-y-1/2",
              meta.touched && meta.error ? "text-fire" : "text-artichoke"
            )}
          >
            {meta.error ? <Alert /> : null}
            {!meta.error && meta.touched ? <Check /> : null}
          </span>
        </div>

        {toggleEdit && (
          <button
            type="button"
            className="border-t border-r border-b border-grey rounded-r bg-grey-light px-4 py-2"
            onClick={(e) => {
              e.preventDefault();
              onToggle(!changeField);
              setChangeField(!changeField);
            }}
          >
            {changeField ? "Save" : "Edit"}
          </button>
        )}
      </div>
      {meta.touched && meta.error ? (
        <span className="text-xs text-fire">{meta.error}</span>
      ) : null}
    </div>
  );
};

export default Input;
