import Head from "next/head";
import ErrorPage from "pages/_error";
import useSWR from "swr";

import * as yup from "yup";

import { Formik, Form } from "formik";

import Breadcrumbs from "components/layout/breadcrumbs";
import AlertStatus from "components/atoms/alert/alert";
import Toggle from "components/form/inputs/toggle";
import Submit from "components/form/inputs/submit";
import Input from "components/form/inputs/input";
import Textarea from "components/form/inputs/textarea";
import SectionHeading from "components/atoms/headings/section/heading";

const validationSchema = yup.object().shape({
  name: yup.string().required("Please enter a valid name."),
  email: yup.string().email("Invalid email address").required("Required"),
  reason: yup.string(),
  coc: yup.boolean().oneOf([true]).required(),
});

export default function SlackInvitePage({ errorCode, errorMessage }) {
  if (errorCode) {
    return <ErrorPage statusCode={errorCode || 500} err={errorMessage} />;
  }

  let initialValues = {
    name: "",
    email: "",
    coc: true,
    reason: "",
  };

  return (
    <>
      <Head>
        <title>NashDev - Home</title>
      </Head>

      <div className="container mx-auto">
        <Breadcrumbs crumbs={[{ label: "Slack Inite", action: "/slack" }]} />

        <div className="mt-6">
          <SectionHeading heading={`Get an invite to NashDev Slack`} />
        </div>
        <section className="my-8">
          <p>
            <strong>Hello!</strong> You may request an invitation below to be
            approved by our moderators.
            <strong className="block my-4">
              This will typically take a few hours, but we'll get you in as soon
              as possible!
            </strong>
          </p>
        </section>
        <section className="my-8 max-w-md">
          <Formik
            initialValues={initialValues}
            onSubmit={async (values, { setSubmitting, setStatus }) => {
              const req = await fetch(`/api/slack/invite`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(values),
              });
              const res = await req.json();
              const { error } = res;

              setSubmitting(false);

              if (!error) {
                setStatus({
                  type: "info",
                  message: res.status,
                });
                return;
              }
              setStatus({
                type: "error",
                message: error,
              });
            }}
            validationSchema={validationSchema}
          >
            {({ status, values, isSubmitting, isValid, setFieldValue }) => (
              <>
                <AlertStatus status={status} />

                <Form className="mt-6">
                  <Input type="text" label="Name" name="name" />

                  <Input type="text" label="E-mail" name="email" />

                  <Textarea
                    label="Why do you want to join the NashDev Slack Channel?"
                    name="reason"
                    help="If you're not local to Nashville, please tell us in a few
                      words why you want to join this community."
                  />

                  <div className="flex mt-8">
                    <Toggle name="coc" id="coc" />
                    <div className="ml-4">
                      I agree to abide by the{" "}
                      <a
                        href="/conduct"
                        target="_blank"
                        className="text-blue underline"
                      >
                        code of conduct.
                      </a>
                    </div>
                  </div>

                  <Submit
                    name="submit"
                    label={isSubmitting ? "•••" : "Request Invitation"}
                    className="mt-6"
                    disabled={isSubmitting || !isValid}
                    loading={isSubmitting}
                  />
                </Form>
              </>
            )}
          </Formik>
        </section>
      </div>
    </>
  );
}
