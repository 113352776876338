/**
 * TODO: This feels very manual. That might prove to be the way it
 * needs to be but consider a way of deriving this from the url
 */
import Head from "next/head";
import Link from "next/link";
import { getPublicUrl } from "utils";

const Breadcrumbs = ({ crumbs }) => {
  const segments = [...crumbs];
  const currentSegment = segments.pop();

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home",
        item: getPublicUrl("/slack"),
      },
    ]
      .concat(
        segments.map((segment, idx) => ({
          "@type": "ListItem",
          position: idx + 2,
          name: segment.label,
          item: getPublicUrl(segment.action),
        }))
      )
      .concat([
        {
          "@type": "ListItem",
          position: segments.length + 2,
          name: currentSegment.label,
        },
      ]),
  };
  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(structuredData),
          }}
        />
      </Head>
      <div className="container mx-auto">
        <nav aria-label="breadcrumbs">
          <ol className="flex text-sm text-grey-dark">
            <li>
              <Link href="/slack">
                <a>Home</a>
              </Link>
            </li>
            {segments.length !== 0 &&
              segments.map((crumb) => (
                <li key={crumb.action}>
                  <Link href={crumb.action}>
                    <a>{crumb.label}</a>
                  </Link>
                </li>
              ))}
            <li aria-current="page" className="text-black font-semibold">
              {currentSegment.label}
            </li>
          </ol>
        </nav>
        <style jsx>{`
          li:not(:last-of-type):after {
            content: "/";
            display: inline-block;
            margin: 0 0.5rem;
          }
        `}</style>
      </div>
    </>
  );
};

export default Breadcrumbs;
