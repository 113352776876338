/**
 * TODO: Build this so it accepts arbitrary iconography, text as children?
 * Allow icon bg color change (green, yellow, red, etc)
 */
import Link from "next/link";
import cx from "classnames";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

const IconButton = ({ action, className, icon, size = "lg", text }) => {
  // action is function?

  return (
    <ConditionalWrapper
      condition={typeof action === "string"}
      wrapper={children => <Link href={action}><a className={cx("inline-block", className)}>{children}</a></Link>}
    >
      <div
        className={cx("inline-flex shadow cursor-pointer", className)}
        onClick={typeof action === "function" ? action : null}
      >
        <div className={cx(
          "border-r border-r-1 border-grey-lighter bg-wind",
          {
            "p-6": size === "xl",
            "p-4": size === "lg",
            "p-2": size === "sm",
          }
        )}>
          <FontAwesomeIcon className="text-cosmos" icon={icon || faAngleRight} />
        </div>
        <div className={cx(
          "bg-white font-semibold",
          {
            "px-12 py-6": size === "xl",
            "px-8 py-4": size === "lg",
            "px-4 py-2": size === "sm",
          }
        )}
        >
          <p>{text}</p>
        </div>
      </div>
    </ConditionalWrapper>
  )
};

export default IconButton;

